@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9fbfc;
  font-size: 15px;
}

h6 {
  line-height: 1.5rem;
  font-size: 1rem;
  margin: 0;
}
